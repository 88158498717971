<template>
  <div class="text-center">
    <v-menu offset-y 
            bottom
            left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-apps</v-icon>

        </v-btn>
      </template>
        <v-list>
            <v-list-item
            v-for="(tool, index) in tools"
            :key="index"
            :href="tool.link" target="_blank"
            >
                <v-list-item-avatar tile>
                    <v-img :src="tool.img+tool.category+'.png'"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ tool.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
  </div>
</template>

<script>
    import toolsData from "@/assets/tools.json";

  export default {
    name: 'AppBarMenuTools',

    data: () => ({

        tools: toolsData
    })
}
</script>